<script>
  import { dev } from "$app/environment";
  import Icons from "$lib/main/components/Icons.svelte";

  export let session;
  export let post = null;
</script>

{#if dev}
  <div class="admin-view">
    {#if session?.user}
      <a href="/admin" class="admin">Admin</a>

      <hr>

      {#if post}
        <a href="/admin/content/{ post.id }" class="minor">Edit post</a>
        <span>Post ID: { post.id}</span>
      {/if}
    {:else}
      <a href="/login" class="login"><span>Login</span><Icons name="log-in" strokeWidth="2" /></a>
    {/if}
  </div>
{/if}